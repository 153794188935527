body {
  overflow: hidden;
}

.ant-menu-inline-collapsed > .ant-menu-item .anticon,
.ant-menu-inline-collapsed
  > .ant-menu-item-group
  > .ant-menu-item-group-list
  > .ant-menu-item
  .anticon,
.ant-menu-inline-collapsed
  > .ant-menu-item-group
  > .ant-menu-item-group-list
  > .ant-menu-submenu
  > .ant-menu-submenu-title
  .anticon,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .anticon {
  font-size: 22px;
}
.ant-menu-inline-collapsed > .ant-menu-item,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item,
.ant-menu-inline-collapsed
  > .ant-menu-item-group
  > .ant-menu-item-group-list
  > .ant-menu-submenu
  > .ant-menu-submenu-title,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
  padding: 0 29px !important;
}

.box {
  position: relative;
  border: 1px solid #ddd;
  border-radius: 3px;
}

.ant-table-thead > tr > th, .ant-table-tbody > tr > td {
  padding: 4px 4px;
  border-left: 1px solid #ddd !important;
  font-size: 14px;
}
.ant-table-tbody > tr > td {
 background-color: white;
}
.ant-table-thead > tr > th:last-child, .ant-table-tbody > tr > td:last-child {
  border-right: 1px solid #ddd !important;
}

.ant-table-thead > tr:first-child > th:first-child {
  border-top-left-radius: 0px;
}
.ant-table-thead > tr:first-child > th:last-child {
  border-top-right-radius: 0px;
}

.ant-table-thead > tr > th {
  background-color: lightslategray;
  text-align: center;
  color: white;
  padding: 10px 10px;
}

.odd {
  background-color: #fff;
  cursor: pointer;
}
.even {
  background-color: #eee;
  cursor: pointer;
}

.ant-menu-sub.ant-menu-inline > .ant-menu-item, .ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 34px;
  line-height: 34px;
  margin-bottom: 0px;
}

.ant-pagination-item-active {
  background: #1890ff;
  border-color: #1890ff;
}

.ant-pagination-item-active a {
  color: #ffffff;
}

.ant-form-item-label {
  line-height: 15px;
  font-weight: bold;
}  

.ant-input {
  border: 2px solid #ddd;
  border-radius: 4px;
  height: 34px;
  background-color: #f9f9f9;
}

.ant-select-selection { 
  border: 2px solid #ddd;
  border-radius: 4px;
  background-color: #f9f9f9;
}

.ant-select-selection--multiple .ant-select-selection__choice {
  background-color: #778899;
  color: white;
  border-radius: 2px;
  border: 0;
}

.img-rounded {
  border-radius: 5px;
  border: 3px solid #696c6f;
}
// default light theme

// for boxed-layout
html,
body {
  background-color: $body-bg;
}

.app-sidenav.ant-layout-sider-collapsed .sidenav-header {
  padding-left: 11px;
}
